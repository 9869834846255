import React from 'react';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Team from '../components/Team';

export default () => (
  <React.Fragment>
    <SEO
      title="Our Team of Provo Therapists | Mindset Family Therapy"
      description="Our team of experienced therapists at Mindset Family Therapy provide knowledgeable, empathetic, and personalized care for you or your loved one."
      pathname="/team"
    />
    <Layout>
      <Team />
    </Layout>
  </React.Fragment>
);
